body {
  margin: 0;
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  line-height: 1.6;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Keep the existing navbar styles but adapted to Bootstrap's classes */
.navbar {
  box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
}

.navbar-brand img {
  width: 100px;
  height: auto;
}

.nav-link {
  font-weight: bold;
}

.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);
}

.logo-container h1 {
  margin-bottom: 20px;
}

.logo {
  max-width: 300px;
  height: auto;
  margin-top: 100px;
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #87E8EC;
}
