.results-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  margin-bottom: 80px;
}

h1 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

h6 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.date-nav {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.date-nav button {
  text-decoration: none;
  background-color: #0FF;
  color: black;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.date-picker {
  text-align: center;
  margin-bottom: 20px;
}

.date-picker input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0 10px;
}

form {
  margin-bottom: 80px;
}

form input[type="date"] {
  padding: 5px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

form select {
  padding: 5px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

form input[type="submit"] {
  padding: 5px 15px;
  background-color: #0FF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
}

th {
  background-color: #0FF;
  color: black;
}

/* Changed to match the custom styling from the original */
.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #87E8EC;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.manual {
  background-color: #ffe0e0;
}

.multiplier {
  font-weight: bold;
  color: #e91e63;
}

.multiplier[data-value="1"] {
  color: #333;
  font-weight: normal;
}

.result-time {
  font-weight: bold;
}

.result-number {
  font-size: 1.2em;
  font-weight: bold;
}

.loading, .error {
  text-align: center;
  padding: 20px;
  font-weight: bold;
  margin-top: 20px;
}

.error {
  color: #d32f2f;
}

.stats {
  margin-top: 20px;
  font-style: italic;
  color: #666;
}

.table {
  margin-top: 15px;
}

/* Bootstrap overrides */
.table-bordered {
  border: 1px solid #ddd;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #ddd;
}

.container div {
  margin-bottom: 15px;
}

@media screen and (max-width: 600px) {
  table {
    font-size: 0.8em;
  }
  th, td {
    padding: 8px 4px;
  }
  form input[type="date"],
  form select,
  form input[type="submit"] {
    margin-bottom: 10px;
  }
}
